/*! DFC AB Testing Framework: 2.0.0.2024-11-15T21:56:13.159Z */
"use strict";

// framework v2 helper functions / bootstrapping
if (!function lsTest() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}()) {
  throw new Error('DFC: localStorage required.');
}
(() => {
  const dfc = window.dfc; // for minification

  const doNotTrack = () => {
    localStorage.setItem('dfc-do-not-track', 'true');
    dfc.DO_NOT_TRACK = true;
  };
  dfc.DO_NOT_TRACK = localStorage.getItem('dfc-do-not-track') === 'true';
  if (/\b(bot|crawler|spider|slurp)\b/i.test(navigator.userAgent)) {
    doNotTrack();
  }
  const searchParams = new URLSearchParams(location.search);

  // first we reset params if needed
  if (searchParams.get('dfc-reset') || searchParams.get('dfc-reset-all')) {
    for (const key in localStorage) {
      if (/^dfc[-:]/.test(key)) {
        localStorage.removeItem(key);
      }
    }
  }

  // then we recoup the params set from preview link
  const prefix = 'dfc-exp-';
  try {
    const force = new RegExp(`^${prefix}`);
    searchParams.forEach((value, key) => {
      if (force.test(key)) {
        localStorage.setItem(key, value);
        doNotTrack();
      }
    });
  } catch (e) {
    // do nothing b/c old browsers don't support URLSearchParams
    // eslint-disable-next-line no-console
    console.error(e);
  }

  // Reset disable if searchParams.get('dfc-env')
  if (searchParams.get('dfc-env')) {
    localStorage.removeItem('dfc-disable');
  }

  // iife flags for closure
  const [env, disable] = (() => {
    return ['env', 'disable'].map(key => {
      const ns = `dfc-${key}`;
      const value = searchParams.get(ns) || getWithExpiry(ns);
      if (value) {
        setWithExpiry(ns, value);
      }
      dfc[key] = value;
      return value;
    });
  })();

  // then we disable and adjust based on config
  if (disable) {
    throw new Error('dfc: disabled');
  }

  // add do not track flag for any env
  if (env) doNotTrack();
  if (!dfc.onExp) {
    dfc.onExp = fn => {
      dfc.experiments.forEach(arr => fn(arr[0], arr[1]));
      dfc.trackers.push(fn);
    };
  }
  const loadScript = (url, callback, onerror) => {
    // log('loadScript', url);
    const script = document.createElement('script');
    const scripts = document.getElementsByTagName('script')[0];
    script.src = url;
    if (callback) script.onload = () => callback(script);
    // eslint-disable-next-line no-console
    script.onerror = onerror || (e => console.error('dfc.loadScript', url, e));
    scripts.parentNode.insertBefore(script, scripts);
  };
  dfc.loadScript = src => new Promise(loadScript.bind(null, src));
  dfc.loadLink = url => {
    // log('loadLink', url);
    const head = document.getElementsByTagName('HEAD')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    head.appendChild(link);
  };
  if (env === 'dev') {
    dfc.host = `https://localhost:8888`;
  } else if (!env || env === 'main') {
    // dfc.host = `https://digitalfuelcapital.pages.dev`;
    dfc.host = `https://digitalfueloptimize.com`;
  } else {
    // shouldn't be possible to get here but just in case
    if (!env) throw new Error('DFC ENV required for non-main env');
    dfc.host = `https://${env}.digitalfuelcapital.pages.dev`;
  }

  /****************************************************************************
   * Pivot off environment flag for loading preview links
   *****************************************************************************/

  if (/^(staging|dev|(pr|release)-\d+)$/.test(env) && !window._dfcEnvLoaded) {
    window._dfcEnvLoaded = {
      env
    };
    const client = (() => {
      const script = document.querySelector('script[src*="digitalfueloptimize.com/"], script[src*="digitalfuelcapital.pages.dev/"], script[src*="dgjvwr5wei7tc.amplifyapp.com/"]');
      const src = (script && script.getAttribute('src') || '//./').split('//')[1];
      const client = src.split('/')[1];
      return client;
    })();

    // if we're not on the desired env then load it
    loadScript(`${dfc.host}/${client}/index.js`);
    throw new Error(`dfc-env set - stopping execution to load ${env}`);
  }

  // prevent double framework load from timex
  if (dfc.loaded) {
    throw new Error(`window.dfc.loaded===true - don't load twice`);
  }
  dfc.loaded = true;

  // Logging
  const noop = () => {};
  dfc.log = noop;
  dfc.error = noop;
  if (env) {
    // eslint-disable-next-line no-console
    dfc.log = console.log.bind(console, 'dfc');
    // eslint-disable-next-line no-console
    dfc.error = console.error.bind(console, 'dfc');
    dfc.log('running v2.js version', JSON.stringify({
      env: env,
      inline: dfc.version
    }));
    if (env === 'dev') {
      loadScript(`https://localhost:35729/livereload.js?snipver=1`);
    }
    loadScript(`${dfc.host}/notify.js`);
  }
  async function wrap(str, fn, ...args) {
    try {
      return await fn(...args);
    } catch (e) {
      if (e) {
        dfc.error(str, e);
      }
    }
  }
  dfc.wrap = wrap;
  async function safe(fn, ...args) {
    try {
      return await fn(...args);
    } catch (e) {
      if (env) {
        // eslint-disable-next-line no-console
        console.error('DFC ERROR', e);
      }
    }
  }
  dfc.safe = safe;
  dfc.getCookie = function (cname) {
    const nameEQ = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  // domReady
  dfc.ready = new Promise(res => {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      res();
    } else {
      document.addEventListener('DOMContentLoaded', res);
    }
  });

  // window load
  dfc.load = new Promise(res => {
    dfc.log('document.readyState', document.readyState);
    if (document.readyState === 'complete') {
      res();
    } else {
      window.addEventListener('load', res);
    }
  });
  let stop = false;
  dfc.load.then(() => {
    dfc.log('page loaded');
    // eslint-disable-next-line no-restricted-globals
    setTimeout(() => {
      stop = true;
    }, 250);
  });

  /**
   * Add a style tag to the head
   * @param {*} css
   * @returns {HTMLStyleElement}
   * @example
   * dfc.addStyle(`.el-class { color: red; }`);
   * @example with id for removal later
   * const styleId = dfc.addStyle('body {display:none}');
   * await dfc.load
   * dfc.qs(`#${styleId}`).remove();
   * @example remove after load event
   * dfc.load.then(() => {
   * const $style = dfc.qs(`#${styleId}`);
   * $style && $style.remove();
   * });
   */
  let _idx = 0;
  dfc.addStyle = css => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.id = `dfc-style-${_idx++}`;
    head.appendChild(style);
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    return style.id;
  };
  const jq = (callback, onError) => {
    const $ = window.jQuery;
    if ($ && $.fn && $.fn.jquery) safe(callback.bind(null, $));else if (stop) onError(new Error('dfc.jQuery jQuery not found'));else window.requestAnimationFrame(jq.bind(null, callback, onError));
  };

  // weird cache so we don't poll all the time
  let jQuery = null;
  Object.defineProperty(dfc, 'jQuery', {
    get: function () {
      return jQuery || (jQuery = new Promise(jq.bind(null)));
    }
  });
  const raf = (selector, callback, onError) => {
    const $els = document.querySelectorAll(selector);
    const found = !!$els.length;
    if (found) return safe(callback.bind(null, $els));
    if (stop) onError && onError();else window.requestAnimationFrame(raf.bind(null, selector, callback, onError));
  };

  /**
   * @param {*} selector
   * @returns {Promise<HTMLElement[]>}
   * @example
   * const els = await dfc.raf('.el-class');
   * els.forEach(el => el.style.color = 'red');
   */
  dfc.raf = selector => new Promise(raf.bind(null, selector));

  /**
   * @param {*} selector
   * @returns {Promise<HTMLElement>}
   * @example
   * const el = await dfc.find('#my-id');
   * el.style.color = 'red';
   */
  dfc.find = selector => dfc.raf(selector).then($els => $els[0]);
  dfc.$raf = selector => new Promise(jq.bind(null)).then(async $ => {
    const $els = await dfc.raf(selector);
    return $($els);
  });
  dfc.qs = document.querySelector.bind(document);
  dfc.qsa = (selector, context = document) => Array.from(context.querySelectorAll(selector));
  dfc.createEl = str => {
    const div = document.createElement('div');
    div.innerHTML = str.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  };
  dfc.live = (selector, event, callback, context) => {
    // helper for enabling IE 8 event bindings
    function addEvent(el, type, handler) {
      if (el.attachEvent) el.attachEvent('on' + type, handler);else el.addEventListener(type, handler);
    }
    // live binding helper using matchesSelector
    function live(selector, event, callback, context) {
      addEvent(context || document, event, e => {
        let found,
          el = e.target || e.srcElement;
        while (el && el.matches && el !== context && !(found = el.matches(selector))) el = el.parentElement;
        if (found) callback.call(el, e);
      });
    }
    live(selector, event, callback, context);
  };

  // eslint-disable-next-line no-restricted-globals
  dfc.sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  dfc.waitFor = async f => {
    let resp;
    while (!(resp = f()) && !stop) await new Promise(requestAnimationFrame);
    if (resp) return resp;else throw new Error('dfc.waitFor: f() not true by stop', f);
  };
  dfc.waitForever = async f => {
    let resp;
    while (!(resp = f())) await new Promise(requestAnimationFrame);
    return resp;
  };
  dfc.waitForSeconds = async (f, seconds = 5) => {
    let resp,
      stop = false;
    // eslint-disable-next-line no-restricted-globals
    setTimeout(() => {
      stop = true;
    }, seconds * 1000);
    while (!(resp = f()) && !stop) await new Promise(requestAnimationFrame);
    return resp;
  };

  // dfc.removeElementById = (id) => {
  //   const elem = document.getElementById(id);
  //   return elem && elem.parentNode.removeChild(elem);
  // };

  // dfc.setParam = (str, k, v) => {
  //   const url = (() => {
  //     try {
  //       return new URL(str);
  //     } catch (e) {
  //       return new URL(str, location.href);
  //     }
  //   })();
  //   url.searchParams.set(k, v);
  //   return url.toString();
  // };

  // dfc.$setParam = ($a, k, v) => {
  //   const href = $a.attr('href');
  //   return $a.attr('href', dfc.setParam(href, k, v));
  // };

  // cspell:ignore cvalue, exdays
  // function setCookie(cname, cvalue, exdays) {
  //   const d = new Date();
  //   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  //   const expires = 'expires=' + d.toUTCString();
  //   document.cookie = cname + '=' + cvalue + ';' + expires + ';path`=/';
  // }

  /**
   * Hoisted Helper Functions
   */

  async function run(row, fn, ...args) {
    // weird hack to paste run in console when in dev
    if (typeof row === 'function') {
      // eslint-disable-next-line no-console
      console.error('The DFC files should only be utilized after they have undergone compilation. This code ' + 'is intended to serve as a starting point, it should not be implemented as is and ' + 'should be refactored before being integrated into production.');
      return env && dfc.wrap('run', row, ...[fn, ...args]);
    }
    const {
      experiment,
      expId,
      status
    } = row;
    const key = `dfc-exp-${expId}`;
    dfc.friendly[expId] = row;
    // set 1 for 100%s
    if (status === '100%' && parseInt(localStorage.getItem(key), 10) !== 1) {
      localStorage.setItem(key, 1);
    }

    // run everything on non main envs
    if (env && env !== 'main') {
      return dfc.wrap(experiment, fn, ...args);
    } else {
      // on deck run only when env flag set:
      if (status === 'ONDECK' && env === 'main') return dfc.wrap(experiment, fn, ...args);
      // on main, run live and 100%s
      if (status === 'LIVE' || status === '100%') return dfc.wrap(experiment, fn, ...args);
    }
  }
  dfc.run = run;
  dfc.friendly = {};
  dfc.prebucket = (id, variations = 2) => {
    let idx = window.dfc(`prebucket-${id}`, variations);
    if (/^100-/.test(id)) idx = 1;
    localStorage.setItem(`dfc-exp-${id}`, idx);
    return {
      idx,
      activate: () => window.dfc(id, variations)
    };
  };

  /**
   * Optimize and Experience Linking
   */

  dfc.shouldIgnoreRegex = /^(100|preview|prebucket|sku|ignore|todo|ondeck)/i;
  dfc.shouldIgnore = id => {
    if (!id) {
      dfc.log('dfc.shouldIgnore: ID IS NULL', id);
      return true;
    }
    if (dfc.DO_NOT_TRACK) {
      dfc.log('dfc.shouldIgnore: DO_NOT_TRACK', id);
      return true;
    }
    if (dfc.shouldIgnoreRegex.test(id)) {
      dfc.log('dfc.shouldIgnore: ignore', id);
      return true;
    }
    return false;
  };
  dfc.trackExperiences = async ({
    dedupeMs = 1000 * 60 * 60 * 12,
    onReady = false
  } = {}) => {
    // https://developers.google.com/analytics/devguides/collection/ga4/integration
    await dfc[onReady ? 'ready' : 'load'];
    dfc.onExp((id, idx) => {
      if (dfc.shouldIgnore(id)) return;
      if (dedupeMs > 1) {
        // hack to confirm valid number
        const ns = `dfc-ga4exp-${id}`;
        const value = dfc.getWithExpiry(ns);
        if (parseInt(value, 10) === parseInt(idx, 10)) return;
        dfc.setWithExpiry(ns, idx, dedupeMs);
      }
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('event', 'experience_impression', {
        // Replace the value with the Experiment-variant ID
        exp_variant_string: `DFC-${id}-${idx}`
      });
    });
  };
  dfc.trackClarity = async () => {
    await dfc.load;
    if (!window.clarity) return;
    /*global clarity*/

    const activated = [];
    dfc.onExp((id, idx) => {
      if (dfc.shouldIgnore(id)) return;
      activated.push([id, idx]);
    });
    if (activated.length) {
      // prettier-ignore
      clarity('set', 'experiment', activated.map(([id, idx]) => `${id}:v${idx}`));
    }
    activated.push = ([id, idx]) => {
      clarity('set', 'experiment', `${id}:v${idx}`);
    };
  };

  // call like this: dfc.track('exp1-right-click');
  dfc.track = async _name => {
    dfc.log('dfc.track: ', _name);
    if (dfc.DO_NOT_TRACK) return dfc.log('dfc.DO_NOT_TRACK');
    await dfc.load; // wait till load

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('event', 'dfc_track_' + _name.toLowerCase().replace(/[-\s]+/g, '_').replace(/[^a-z0-9_]/g, '').replace(/__+/g, '_').replace(/^_|_$/g, ''));
  };
  dfc.setWithExpiry = setWithExpiry;
  function setWithExpiry(key, value, ms = 1000 * 60 * 30) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ms
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  dfc.getWithExpiry = getWithExpiry;
  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
})();
dfc.trackClarity();
dfc.trackExperiences();
"use strict";

// https://digitalfueltesting.atlassian.net/browse/CRO-1249
// CRO-1249 - D - Cart - Hide Nav Menu
// Portco: limelush.com

dfc.run({
  "client": "limelush.com",
  "status": "LIVE",
  "experiment": "CRO-1249-d-cart-hide-nav-menu.js",
  "expId": "CRO-1249-d-cart-hide-nav-menu",
  "jiraCard": "CRO-1249"
}, async () => {
  // Only run on desktop
  if (!window.matchMedia('(min-width: 959px)').matches) return;
  // Only run on the cart page
  if (!location.pathname.includes('/cart')) return;
  const idx = window.dfc('CRO-1249-d-cart-hide-nav-menu');
  if (idx === 0) return;

  // Add styles
  dfc.addStyle(` .site-nav.site-navigation { display: none !important; } #StickyHeaderWrap { height: 110px !important; } `);
});

// https://digitalfueltesting.atlassian.net/browse/CRO-234
// CRO-234 - A - PDP - Recently Viewed Carousel
// Portco: limelush.com

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "CRO-234-a-pdp-recently-viewed-carousel.js",
  "expId": "ondeck-CRO-234-a-pdp-recently-viewed-carousel",
  "jiraCard": "CRO-234"
}, async () => {
  // Only run on the PDP
  if (!location.pathname.includes('/products/')) return;
  const idx = window.dfc('ondeck-CRO-234-a-pdp-recently-viewed-carousel'); // Was 100%
  if (idx === 0) return;

  // Add Styles
  dfc.addStyle(` .recently-viewed { display: block; } `);
});

// https://digitalfueltesting.atlassian.net/browse/CRO-247
// CRO-247 - A - PDP - Add free shipping threshold to PDP and highlight this info in the cart
// Portco: limelush.com

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "CRO-247-a-pdp-add-free-shipping-threshold.js",
  "expId": "ondeck-CRO-247-a-pdp-add-free-shipping-threshold",
  "jiraCard": "CRO-247"
}, async () => {
  await dfc.load;
  const $shippingTruckSvg = ` <svg xmlns="http://www.w3.org/2000/svg" width="25.18" height="12.862" viewBox="0 0 25.18 12.862"> <path id="Shipping_Icon" data-name="Shipping Icon" d="M11.077,26.855a.435.435,0,0,0-.432.433V37.662a.435.435,0,0,0,.432.433h2.305a2.027,2.027,0,0,0,3.972,0h6.791a2.029,2.029,0,0,0,3.975,0h1.314a.434.434,0,0,0,.433-.433V33.236a.425.425,0,0,0-.036-.176l-1.522-3.394a.432.432,0,0,0-.394-.256h-4.65V27.288h0a.435.435,0,0,0-.435-.433Zm.436.868H22.4v9.5H17.355a2.027,2.027,0,0,0-3.971,0h-1.87Zm-3.993.785h0a.434.434,0,0,0,0,.868H8.61a.435.435,0,0,0,.436-.433.435.435,0,0,0-.436-.435Zm15.745,1.769h4.368l1.327,2.962H26.368L25.6,32.22h-2.33ZM5.98,31.476a.434.434,0,1,0,0,.868H8.61a.435.435,0,0,0,.436-.435.435.435,0,0,0-.436-.433Zm17.287,1.612h1.9l.77,1.019H29v3.118H28.12a2.029,2.029,0,0,0-3.975,0h-.879ZM5.124,34.445a.432.432,0,1,0,0,.864H8.611a.432.432,0,1,0,0-.864ZM15.37,36.473a1.188,1.188,0,1,1-1.164,1.188A1.171,1.171,0,0,1,15.37,36.473Zm10.766,0a1.188,1.188,0,1,1-1.167,1.188A1.171,1.171,0,0,1,26.136,36.473Z" transform="translate(-4.688 -26.855)" fill="#42a183" fill-rule="evenodd"/> </svg> `;
  const {
    idx,
    activate
  } = dfc.prebucket('ondeck-CRO-247-a-pdp-add-free-shipping-threshold');

  // Only run on the PDP
  if (location.pathname.includes('/products/')) {
    // Get the data-product-blocks element
    const $productBlocks = await dfc.find('[data-product-blocks]');
    activate();
    if (idx === 0) return;

    // Add Style
    dfc.addStyle(` .shipping-message_container { display: flex; align-items: center; } .shipping-message_container svg { margin-right: 5px; } .shipping-message_container span { font-weight: 700; } @media (max-width: 767px) { .shipping-message_container { font-size: 14px; } } `);

    // From the $productBlocks element, get shipping-message element
    const $shippingMessage = $productBlocks.querySelector('.shipping-message');

    // If the dataProductPrice is less than 50, log it
    if (Number($productBlocks.querySelector('[data-product-price]').textContent.replace(/[^0-9.-]+/g, '')) < 50) {
      const $shippingMessageTextLess = ` <div class="shipping-message_container">${$shippingTruckSvg}<span>FREE Shipping</span>&nbsp;(for orders&nbsp;<span>$50+</span>).</div> `;
      $shippingMessage.innerHTML = $shippingMessageTextLess;
    } else {
      const $shippingMessageTextLess = ` <div class="shipping-message_container">${$shippingTruckSvg}<span>FREE Shipping</span>&nbsp;for this item.</div> `;
      $shippingMessage.innerHTML = $shippingMessageTextLess;
    }
  } else if (location.pathname.includes('/cart')) {
    // Get the order-summary__button-wrapper element
    const $orderSummaryButtonWrapper = await dfc.find('.order-summary__button-wrapper');
    activate();
    if (idx === 0) return;
    dfc.addStyle(` .shipping-message_container { display: flex; align-items: center; justify-content: center; } .shipping-message_container svg { margin-right: 5px; } .shipping-message_container span { font-weight: 700; } .slider-container { position: relative; width: 100%; height: 10px; background-color: #D8D8D8; margin-bottom: 10px; } .slider-fill { position: absolute; top: 0; left: 0; height: 100%; background-color: #42A183; } .cart__mobile-only .cart__checkout-message strong { color: #42A183; } .cart__checkout-message { display: block !important; } `);

    // Get the .cart__checkout-message element from the $orderSummaryButtonWrapper element
    const $cartCheckoutMessage = $orderSummaryButtonWrapper.querySelector('.cart__checkout-message');

    // Get the Dollar amount in the cartCheckoutMessage element
    // const $cartCheckoutMessageDollarAmount = Number($cartCheckoutMessage.textContent.replace(/[^0-9.-]+/g, ''));
    const $cartCheckoutMessageDollarAmount = Number($cartCheckoutMessage.textContent.replace(/[^0-9.-]+/g, '').replace(/(\..*)\./g, '$1')) || 0;
    const $shippingSlider = dfc.createEl(` <div> <div class="slider-container"> <div class="slider-fill"></div> </div> <div class="shipping-message_container"> </div> </div> `);

    // If the Dollar amount in the cartCheckoutMessage element is less than 50, use the following text
    addSlider($cartCheckoutMessageDollarAmount.toFixed(2), $shippingSlider, $cartCheckoutMessage, $orderSummaryButtonWrapper);

    //  Listen for dom changes
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        // dfc.log('mutation', mutation);
        // If the mutation is a change to the #product-content element
        if (mutation.target.nodeName === 'DIV') {
          if (mutation.addedNodes.length) {
            // dfc.log('mutation', mutation);
            // dfc.log('mutation.addedNodes', mutation.addedNodes.length);

            const $cartCheckoutMessageDollarAmount = Number($cartCheckoutMessage.textContent.replace(/[^0-9.-]+/g, '').replace(/(\..*)\./g, '$1')) || 0;
            addSlider($cartCheckoutMessageDollarAmount.toFixed(2), $shippingSlider, $cartCheckoutMessage, $orderSummaryButtonWrapper);
          }
        }
      });
    });
    const $dataProducts = document.querySelector('[data-products]');
    observer.observe($dataProducts, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true
    });
  } else {
    return;
  }
  function addSlider($cartCheckoutMessageDollarAmount, $shippingSlider, $cartCheckoutMessage, $orderSummaryButtonWrapper) {
    if ($cartCheckoutMessageDollarAmount > 0) {
      dfc.log('less than 50: ', $cartCheckoutMessageDollarAmount);
      $shippingSlider.querySelector('.shipping-message_container').innerHTML = ` ${$shippingTruckSvg} You're&nbsp;<span>$${$cartCheckoutMessageDollarAmount}</span>&nbsp;away from free shipping! `;
    } else {
      dfc.log('greater than 50: ', $cartCheckoutMessageDollarAmount);
      $shippingSlider.querySelector('.shipping-message_container').innerHTML = ` ${$shippingTruckSvg} Congrats! You qualify for&nbsp;<span>Free Shipping!</span> `;
    }
    $shippingSlider.querySelector('.slider-fill').style.width = `${(50 - $cartCheckoutMessageDollarAmount) / 50 * 100}%`;

    // Remove the text from the $cartCheckoutMessage element
    $cartCheckoutMessage.textContent = '';
    // Append the $shippingSlider element before the $cartCheckoutMessage element
    $cartCheckoutMessage.prepend($shippingSlider);
    // Move the $cartCheckoutMessage element to the top of its parent
    $orderSummaryButtonWrapper.prepend($cartCheckoutMessage);
  }
});

// https://digitalfueltesting.atlassian.net/browse/CRO-250
// CRO-250 - D - PLP - Close all filters
// Portco: limelush.com

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "CRO-250-d-plp-close-all-filters.js",
  "expId": "ondeck-CRO-250-d-plp-close-all-filters",
  "jiraCard": "CRO-250"
}, async () => {
  // Only run on desktop
  if (!window.matchMedia('(min-width: 768px)').matches) return;
  // Only run on PLPs
  if (!location.pathname.startsWith('/collections/')) return;
  if (location.pathname.includes('/products/')) return;
  const $collEL = await dfc.raf('.ss-facet-container');
  if (!$collEL.length) return;

  //await dfc.sleep(50);

  const idx = window.dfc('ondeck-CRO-250-d-plp-close-all-filters'); // Was Live
  if (idx === 0) return;
  $collEL.forEach(el => {
    //dfc.log('el', el.querySelector('.ss-pointer'));
    el.querySelector('.ss-pointer').click();
  });
});

// https://digitalfueltesting.atlassian.net/browse/CRO-593
// CRO-593 - M - PDP - Spacing Cleanup
// Portco: limelush.com

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "CRO-593-m-pdp-spacing-cleanup.js",
  "expId": "ondeck-CRO-593-m-pdp-spacing-cleanup",
  "jiraCard": "CRO-593"
}, async () => {
  // Only on mobile
  // if (window.innerWidth > 1022) return;
  if (!window.matchMedia('(max-width: 1022px)').matches) return;

  // Only run on the PDP
  if (!location.pathname.includes('/products/')) return;
  const idx = window.dfc('ondeck-CRO-593-m-pdp-spacing-cleanup');
  if (idx === 0) return;

  // Add Style
  dfc.addStyle(` .product-single__meta .product-block { margin-bottom: 15px !important; } .product-single__meta .model-info { margin-bottom: 15px !important; } .product-single__meta .product__policies.rte { margin: 15px 0; } `);
  await dfc.load;
  const productImg = await dfc.find('div.image-wrap > img');

  // This is from the flickity-modification.js snippet in the Limelush repo
  if (productImg && productImg.offsetParent) {
    document.querySelector('.flickity-viewport').style.height = `${productImg.offsetHeight + 10}px`;
  }
});

// https://digitalfueltesting.atlassian.net/browse/CRO-638
// CRO-638 - A - PDP - Move the YMAL carousel above reviews
// Portco: limelush.com

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "CRO-638-a-pdp-move-ymal-above-reviews.js",
  "expId": "ondeck-CRO-638-a-pdp-move-ymal-above-reviews",
  "jiraCard": "CRO-638"
}, async () => {
  // Only run on PDPs
  if (!location.pathname.startsWith('/products/')) return;

  // Get the YMAL carousel -- searchspring-recommendations-product
  // const [$ymal, $productSection] = await Promise.all([
  //   dfc.find('.searchspring-recommendations-product'),
  //   dfc.find('.product-section'),
  // ]);

  const $ymal = await dfc.find('.searchspring-recommendations-product');
  const $productSection = await dfc.find('.product-section');
  const idx = window.dfc('ondeck-CRO-638-a-pdp-move-ymal-above-reviews');
  if (idx === 0) return;

  // Add Style
  dfc.addStyle(` .searchspring-recommendations-product { margin-bottom: 60px !important; border-top: 0 !important; border-bottom: 1px solid var(--color-gray-700); } .page-width .yotpo-main-widget { margin-bottom: 60px !important; } `);

  // Move the YMAL carousel after the PDPs
  $productSection.after($ymal);
});

// https://digitalfueltesting.atlassian.net/browse/CRO-748
// CRO-748 - M - Cart - Reduce the number of Checkout CTAs in the mobile cart and move the quick pay CTAs higher.
// Part 1 - Quick Cart: Icons are deleted and spacing btwn CTAs = 10px.
// Part 2 - Full Cart:
// eslint-disable-next-line no-warning-comments
//    For full cart, the icons are deleted and the margin below the CTA goes from 0 → 20. Also note the bolded shipping info (weight = 700) and text size (12px → 14px)
//    Sticky CTA mimics ATC sticky (hover till it reaches placement, like this PDP, same shadows)
// Portco: limelush.com

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "CRO-748-m-cart-reduce-ctas.js",
  "expId": "ondeck-CRO-748-m-cart-reduce-ctas",
  "jiraCard": "CRO-748"
}, async () => {
  // Only run on mobile
  if (!window.matchMedia('(max-width: 1022px)').matches) return;
  const activate = () => {
    return window.dfc('ondeck-CRO-748-m-cart-reduce-ctas'); // Was 100%
  };

  if (location.pathname.includes('/products/')) {
    await dfc.load;
    const idx = activate();
    if (idx === 0) return;

    // Cart modal: .add-to-bag-modal-container
    // Payment Icons: .add-to-bag-modal__credit-cards img

    // Add the style to the head
    dfc.addStyle(` .add-to-bag-modal__credit-cards { margin-top: 10px; } .add-to-bag-modal__credit-cards img { display: none; } .ju_Con { z-index: 25 !important; } `);
  } else if (location.pathname.includes('/cart')) {
    const idx = activate();
    if (idx === 0) return;

    // Add the style to the head
    // Add the style to the head that makes the .cart__checkout display: none
    dfc.addStyle(` .cart__checkout { display: none; } .cart__checkout-message { bold: 700; font-size: 14px; } .credit-icons { display: none; } .sticky-checkout { position: fixed; bottom: 0; left: 0; right: 0; z-index: 999; background: #fff; padding: 20px; border-top: 1px solid #eaeaea; } `);
    await dfc.ready;

    // Find the 3 btn cart__checkout buttons and remove the first two // dfc.raf()
    const $checkoutButtons = await dfc.raf('.cart__checkout'); //document.querySelectorAll('.cart__checkout');

    // const $testPromise = await dfc.raf('.cart__checkout');
    // dfc.log($testPromise[1]);

    // Copy the first button
    const $stickyButton = $checkoutButtons[0].cloneNode(true);

    // Remove the first two buttons
    $checkoutButtons[0].remove();
    $checkoutButtons[1].remove();

    // Add 20px margin to the last button
    $checkoutButtons[2].style.marginBottom = '20px';

    // Create a form element
    const $stickyForm = document.createElement('form');

    // Add action="/cart", method="post", id="CartPageForm" to the form
    $stickyForm.setAttribute('action', '/cart');
    $stickyForm.setAttribute('method', 'post');
    $stickyForm.setAttribute('id', 'CartPageForm');

    // Add the button to the sticky div
    $stickyForm.classList.add('sticky-checkout');
    $stickyForm.appendChild($stickyButton);

    // Add the $stickyForm to the body
    document.body.appendChild($stickyForm);

    // Hide the $stickyForm when the .product-single__form is visible and vice versa
    new IntersectionObserver(entries => {
      entries.forEach(entry => {
        $stickyForm.style.display = entry.isIntersecting ? 'none' : 'block';
      });
    }, {
      threshold: 0
    }).observe($checkoutButtons[2]);

    // when the $stickyButton is clicked, add the btn--loading class to the $stickyButton --> this will show the loading spinner
    $stickyButton.addEventListener('click', () => {
      $stickyButton.classList.add('btn--loading');
    });

    //Change the .cart__checkout to display visible
    dfc.addStyle(` .cart__checkout { display: inherit; } `);
  } else {
    return;
  }
});

// https://digitalfueltesting.atlassian.net/browse/CRO-752
// CRO-752 - A - Cart - Urgency
// Portco: limelush.com

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "CRO-752-a-cart-urgency.js",
  "expId": "ondeck-CRO-752-a-cart-urgency",
  "jiraCard": "CRO-752"
}, async () => {
  // Only run on the cart page
  if (!location.pathname.includes('/cart')) return;

  // Get the cart__item--variants-available element
  const $cartItemsVariantAvailable = await dfc.raf('.cart__item--variants-available');
  const idx = window.dfc('ondeck-CRO-752-a-cart-urgency'); // Was 100%
  if (idx === 0) return;

  // Add styles
  dfc.addStyle(` .cart__item--variants-available { color: var(--color-primary); font-size: 14px; } `);
  populateVariantAvailable($cartItemsVariantAvailable);

  //  Listen for dom changes
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      // dfc.log('mutation', mutation);
      // If the mutation is a change to the #product-content element
      if (mutation.target.nodeName === 'DIV') {
        if (mutation.addedNodes.length) {
          // dfc.log('mutation', mutation);
          // dfc.log('mutation.addedNodes', mutation.addedNodes.length);

          populateVariantAvailable(document.querySelectorAll('.cart__item--variants-available'));
        }
      }
    });
  });
  const $dataProducts = document.querySelector('[data-products]');
  observer.observe($dataProducts, {
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true
  });
  function populateVariantAvailable(cartItemsVariantAvailable) {
    // For each cart__item--variants-available element
    cartItemsVariantAvailable.forEach($cartItemVariantsAvailable => {
      // Get the data-available attribute
      const dataAvailable = $cartItemVariantsAvailable.getAttribute('data-available');

      // If the data-available attribute is less than 0 or greater than 10, move on to the next element
      if (dataAvailable < 0 || dataAvailable > 10) return;
      const $hurryText = `Hurry! Only <strong>${dataAvailable}</strong> left`;

      // Insert the $hurryText into the cart__item--variants-available element
      $cartItemVariantsAvailable.innerHTML = $hurryText;

      // If on mobile, move the cart__item--variants-available element below the product-block--price element
      if (window.innerWidth < 1023) {
        const $price = $cartItemVariantsAvailable.closest('.cart__item').querySelector('.product-block--price');
        $price.after($cartItemVariantsAvailable);
      }

      // Remove the hide class from the cart__item--variants-available element
      $cartItemVariantsAvailable.classList.remove('hide');
    });
  }
});

// https://digitalfueltesting.atlassian.net/browse/CRO-753
// CRO-753 - A - PDP - Update Fit Info
// Portco: limelush.com

// DevNotes:
// This pdp has a nesting of elements: https://www.limelush.com/products/multicolor-printed-one-shoulder-sash-tie-jumpsuit?dfc-exp-preview-04-pdp-fit-info-updates=1

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "CRO-753-a-pdp-update-fit-info.js",
  "expId": "ondeck-CRO-753-a-pdp-update-fit-info",
  "jiraCard": "CRO-753"
}, async () => {
  // Only on product pages
  if (!location.pathname.startsWith('/products/')) return;

  // Get the product title
  const $productInfo = await dfc.find('.product-single__meta');
  const $sizeGuideModal = await dfc.find('.size-guide-modal__container');

  // Get the product-block__description element inside $productInfo
  const $productDescription = $productInfo.querySelector('.product-block__description .rte');

  // Get the parent div of the element with the class "variant-wrapper" inside $productInfo
  const $productBlock = $productInfo.querySelector('.variant-wrapper').parentElement;
  const $els = dfc.qsa('*', $productDescription).reverse();
  const $modelWearing = $els.find(el => el && /^model is wearing./i.test(el.innerText));
  const $modelProfile = $els.find(el => el && /^model.?s profile./i.test(el.innerText));

  // Does the text contain "Model is wearing" and "Model’s Profile"?
  if (!$modelWearing || !$modelProfile) return;
  const idx = window.dfc('ondeck-CRO-753-a-pdp-update-fit-info'); // Was 100%
  if (idx === 0) return;

  // Add Style
  dfc.addStyle(` .model-info { margin-top: 15px; font-size: 14px; font-style: italic; } @media (max-width: 1022px) { .model-info { margin-bottom: 25px; } } .model-size { font-weight: 600; } .size-guide-option-btn { text-decoration: underline; cursor: pointer; color: #DE295C; font-size: 14px; font-weight: 400; font-style: italic; } .size-guide-modal tr:first-of-type td { padding: 8px 2px; } `);

  // Remove any special characters from the $modelWearing text, because some of the text has special characters
  $modelWearing.innerText = $modelWearing.innerText.replace(/[^a-zA-Z0-9 ]/g, ' ');

  // Get the words after "Model is wearing a size"
  const modelWearingSize = $modelWearing.innerText.split('Model is wearing a size').pop();

  // Put the modelWearingSize inside a span
  $modelWearing.innerHTML = $modelWearing.innerHTML.replace(modelWearingSize, `<span class="model-size">${modelWearingSize}</span>`);

  // Add a new div to $productBlock and move the $modelWearing and $modelProfile elements inside divs and then into it
  const $newDiv = document.createElement('div');
  $newDiv.classList.add('model-info');
  $newDiv.innerHTML = ` <div class="model-wearing">${$modelWearing.innerHTML}. <span class="size-guide-option-btn">See Size Guide</span></div> <div class="model-profile">${$modelProfile.innerHTML}</div> `;
  $productBlock.appendChild($newDiv);

  // Remove the $modelWearing and $modelProfile elements
  $modelWearing.remove();
  $modelProfile.remove();
  dfc.addStyle(` .size-guide-option { display: none; } `);

  // Add event listener to the size guide button
  const sizeGuideButton = document.querySelector('.size-guide-option-btn');
  if (sizeGuideButton) {
    sizeGuideButton.addEventListener('click', () => {
      $sizeGuideModal.classList.remove('hide');
    });
  }
});

/* Initialise variation */

dfc.run({
  "client": "limelush.com",
  "status": "ONDECK",
  "experiment": "ll01-hide-empty-reviews.js",
  "expId": "ondeck-patched-till-feb-2023",
  "jiraCard": null
}, async () => {
  if (!window.location.pathname.includes('/products/')) return;

  // wait for yotpo load
  await dfc.raf('.product__info-wrapper a');

  // if we got here and there is a review abort
  const productlist = document.querySelector('.product__info-wrapper a[aria-label]');
  if (productlist) return;
  const idx = window.dfc('ondeck-patched-till-feb-2023'); // Was 100%
  if (idx === 1) {
    dfc.addStyle(` html body .product-block.product-block--header .product__info-wrapper{ display: none; } `);
  }
});